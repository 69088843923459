import React from 'react';
import {
  Box, Text,
} from 'grommet';
import {
  calculateYearlyHousing,
  calculateMonthlyRent,
} from '../../utils/calculator';
import { formatAmount } from '../../utils/numbers';

const AmountItem = ({
  label,
  value,
}) => (
  <Box direction='row' align='baseline'>
    <Text size='xlarge' weight='bold'>
      {value}
    </Text>
    <Text>
      {label}
    </Text>
  </Box>
)

const InsightCalculations = ({ salary = 60000, percent = 28 }) => {
  const yearlyValue = () => formatAmount(calculateYearlyHousing(salary, percent));
  const monthlyValue = () => formatAmount(calculateMonthlyRent(salary, percent));

  return (
    <Box>
      <Text
        weight='bold'
      >
        Housing Budget
      </Text>
      <Box direction='row' justify='between' pad={{ vertical: 'small' }}>
        <AmountItem
          label='/year'
          value={yearlyValue()}
        />
        <AmountItem
          label='/month'
          value={monthlyValue()}
        />
      </Box>
    </Box>
  )

}
export default InsightCalculations;
