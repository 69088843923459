import React from 'react';
import { Grommet } from 'grommet';
import { Router } from '@reach/router';

import theme from './theme';

import Map from '../../screens/Map';
import HousingPercentageProvider from '../../context/housingPercentage';

const App = () => (
  <Grommet full={true} theme={theme}>
    <HousingPercentageProvider>
      <Router>
        <Map path='/' />
      </Router>
    </HousingPercentageProvider>
  </Grommet>
);

export default App;
