import compose from 'lodash/fp/compose';
import get from 'lodash/fp/get';
import defaultTo from 'lodash/fp/defaultTo';
import values from 'lodash/fp/values';
import head from 'lodash/fp/head';
import filter from 'lodash/fp/filter';

import COLOR_GRADES from '../config/color-grades';

// rename to calculate designated monthly rent that will be spent
export const calculateMonthlyRent = (salary, basePercent = 40) => {
  const percentage = basePercent / 100;
  const yearlyRent = salary * percentage;
  return yearlyRent / 12;
}

export const calculateYearlyHousing = (salary, basePercent = 40) => {
  const percentage = basePercent / 100;
  return salary * percentage;
}

// deprecate
export const calculatePercentage = (salary, monthlyCost) => {
  return ((monthlyCost * 12) / salary) * 100
}

export const calculateCostRatioPercentage = (totalIncome, rent, designatedPercent) => {
  const monthSpendature = calculateMonthlyRent(totalIncome, designatedPercent);
  return (rent / monthSpendature) * 100;
}

export const categoryOfAffordability = (percentage) => {
  if (!percentage){
    return undefined
  }
  return compose(
    defaultTo(COLOR_GRADES.IMPOSSIBLE.color),
    get('color'),
    head,
    filter(item => percentage < item.maxValue),
    values,
  )(COLOR_GRADES);
}

export const calculateSteppedCostPerMonth = (totalIncome, percentage, maxValue) => {
  const percentageOfIncome = totalIncome * (percentage / 100);
  const maxValuePercentage = maxValue / 100;
  return (percentageOfIncome * maxValuePercentage) / 12;
}
