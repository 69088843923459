import React, { useState, useCallback, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Map, TileLayer, GeoJSON } from 'react-leaflet';
import { Box, Layer, } from 'grommet';
import isEmpty from 'lodash/isEmpty';

import { useHeight } from '../../hooks';

import data from '../../data/zcta-rental-2.json';

import FilterController from '../../components/FilterController';
import Splash from '../../components/Splash';
import RegionTooltip from '../../components/RegionTooltip';
import ColorLegend from '../../components/ColorLegend';

const attribution = 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
    '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
    'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>'

function MapScreen() {
  const parseZillowData = ({ zipCode, city, timeline }) => {
    const latestMonth = timeline['2019-12'];
    const types = Object.keys(latestMonth);
    const housingStats = types.map(type => ({
      type,
      value: latestMonth[type],
    }));
    // make a utility function to help render components onto leaflet
    const container = document.createElement('div')
    ReactDOM.render((
      <RegionTooltip
        city={city}
        zipCode={zipCode}
        housingStats={housingStats}
      />
    ), container);
    return container;
  }

  const height = useHeight();
  const [geoJsonContext, setGeoJsonConext] = useState(null);
  const setGeoJsonRef = useCallback(context => setGeoJsonConext(context), []);

  const onMouseOver = (layer) => (e) => {
    layer.setStyle({
      color: 'black',
    })
  }

  const onMouseOut = (layer) => (e) => {
    layer.setStyle({
      color: 'none',
    })
  };

  const onEachFeature = ({ properties: { zillow } }, layer) => {
    layer.bindPopup(parseZillowData(zillow));
    layer.on({
      mouseover: onMouseOver(layer),
      mouseout: onMouseOut(layer),
    })
  };

  const [display, setDisplay] = useState(false)
  const [initialInput, setInitialInput] = useState({});

  useEffect(() => {
    if (isEmpty(initialInput)) {
      setDisplay(false);
    } else {
      setDisplay(true);
    }
  }, [initialInput]);

  return (
    <Box
      direction='row'
      height={`${height}px`}
    >
      {!display && (
        <Layer plain={true} full={true} animate={false}>
          <Splash onInitialSubmit={setInitialInput} />
        </Layer>
      )}
      <Map
        center={[47.6261955, -122.3280633]}
        zoom={13}
      >
        <TileLayer
          url='https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw'
          attribution={attribution}
          id='mapbox/streets-v11'
        />
        {display && (
          <Fragment>
            <GeoJSON
              ref={setGeoJsonRef}
              data={data}
              onEachFeature={onEachFeature}
            />
            <ColorLegend />
          </Fragment>
        )}
      </Map>
      {display && (
        <Box width='medium' flex={false}>
          {geoJsonContext && (
            <FilterController
              geoJson={geoJsonContext}
              initialInput={initialInput}
            />
          )}
        </Box>
      )}
    </Box>
  );
}

export default MapScreen;
