import numbro from 'numbro';

export const formatAmount = val => numbro(val).formatCurrency({
  average: true,
  currencyPosition: "prefix",
  mantissa: 1,
});

export const prettyNumber = val => numbro(val).formatCurrency({
  thousandSeparated: true,
  currencyPosition: "prefix"
});

export const unprettyNumber = val => numbro.unformat(val);
