import { usePlugToMap } from '../../hooks';
import ColorLegend from './ColorLegend';

function ColorLegendLeaflet() {
  return usePlugToMap({
    component: ColorLegend,
    position: 'bottomleft',
    name: 'color-legend',
  })
};

export default ColorLegendLeaflet;
