import React from 'react';

import {
  Box, Select, TextInput, FormField, Button
} from 'grommet';

import {
  Field,
} from 'formik';

import { default as OCCUPATIONS } from '../../constants/occupations';
import { prettyNumber, unprettyNumber } from '../../utils/numbers';

function IncomeCard({ income, idx, handleDelete }) {
  return (
    <Box
      border={handleDelete ? true : false}
      pad={handleDelete ? 'xsmall' : 'none'}
    >
      <Field name={`incomes[${idx}].occupation`}>
        {({ field, form }) => (
          <FormField label='Occupation'>
            <Select
              onChange={({ value }) => {
                form.setFieldValue(`incomes[${idx}].income`, value.salary)
                form.setFieldValue(field.name, value.occupation)
              }}
              options={OCCUPATIONS}
              labelKey='occupation'
              value={field.value}
            />
          </FormField>
        )}
      </Field>
      <Field name={`incomes[${idx}].income`}>
        {({ field, form }) => (
          <FormField label='Income'>
            <TextInput
              value={prettyNumber(field.value)}
              onChange={({ target: { value } }) => form.setFieldValue(field.name, unprettyNumber(value) || 0)}
              placeholder='Enter yearly income'
            />
          </FormField>
        )}
      </Field>
      <Box direction='row' justify='end'>
        {handleDelete && <Button onClick={handleDelete}>remove</Button>}
      </Box>
    </Box>
  );
}

export default IncomeCard;
