import React from 'react';
import {
  Box,
  Button,
  Text,
  Heading,
} from 'grommet';
import { useFormikContext } from 'formik';

import concat from 'lodash/fp/concat';

import IncomeCard from './IncomeCard';

function AdditionalIncomesLayer ({
  onClose,
}) {
  const formik = useFormikContext();
  const addIncome = () => {
    const newIncome = {
      occupation: 'Other',
      income: 0,
    }
    formik.setFieldValue('incomes', concat(formik.values.incomes)(newIncome))
  }

  const removeItem = (idx) => {
    const incomes = [...formik.values.incomes];
    incomes.splice(idx, 1);
    formik.setFieldValue('incomes', incomes);
  }

  return (
    <Box
      width='medium'
      pad='medium'
    >
      <Heading level={3}>Additional Incomes</Heading>
      <Box gap='small'>
        {formik.values.incomes.map((income, idx) => (
          <IncomeCard
            key={idx}
            income={income}
            idx={idx}
            handleDelete={() => removeItem(idx)}
          />
        ))}
      </Box>
      <Box margin='small'>
        <Button onClick={addIncome}>
          <Text size='small' color='brand'>
            Add Income
          </Text>
        </Button>
      </Box>
      <Button
        primary
        label='Close'
        onClick={onClose}
      />
    </Box>
  )
}

export default AdditionalIncomesLayer;
