import React, { useContext } from 'react';
import {
  Box,
  Heading,
  Text,
} from 'grommet';

import { HousingPercentageContext } from '../../context/housingPercentage';
import COLOR_GRADES from '../../config/color-grades';
import { calculateSteppedCostPerMonth } from '../../utils/calculator';
import { formatAmount } from '../../utils/numbers';

const LegendItem = ({ label, color }) => {
  const isOver = color === COLOR_GRADES.IMPOSSIBLE.color;
  return (
    <Box direction='row' gap='small'>
      <Box background={color} width='25px' height='25px'/>
      <Text size='small'>
        {`${isOver ? `Over ${label}` : label}/mo`}
      </Text>
    </Box>
  );
};

function ColorLegend () {
  const { housingPercentage: { percentage, totalIncome } } = useContext(HousingPercentageContext);

  return (
    <Box
      background='white'
      pad='small'
      gap='small'
    >
      <Heading
        level={3}
        margin='none'
      >
        Legend
      </Heading>
      <Box gap='xsmall'>
        {Object.keys(COLOR_GRADES).map(key => (
          <LegendItem
            key={COLOR_GRADES[key].color}
            label={formatAmount(
              calculateSteppedCostPerMonth(
                totalIncome,
                percentage,
                COLOR_GRADES[key].maxValue,
            ))}
            color={COLOR_GRADES[key].color}
          />
        ))}
      </Box>
    </Box>
  );
}

export default ColorLegend;
