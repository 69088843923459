import { useEffect } from 'react';
import { useFormikContext } from 'formik';

function AutoSubmit() {
  const { values, handleSubmit } = useFormikContext();
  useEffect(() => {
    handleSubmit();
  }, [values, handleSubmit]);
  return null;
}

export default AutoSubmit;
