import React, { useEffect, useContext } from 'react';
import compose from 'lodash/fp/compose';
import curry from 'lodash/fp/curry';
import get  from 'lodash/fp/get';
import defaultTo  from 'lodash/fp/defaultTo';
import pick  from 'lodash/fp/pick';
import { Box } from 'grommet';
import { calculateCostRatioPercentage, categoryOfAffordability } from '../../utils/calculator';
import RentCalculatorForm from '../RentCalculatorForm';
import { HousingPercentageContext } from '../../context/housingPercentage';

const initialDefaults = {
  percentage: 28,
}

function FilterController ({ leaflet, geoJson = {}, initialInput }) {
  const { setHousingPercentage } = useContext(HousingPercentageContext);
  const gj = geoJson.leafletElement;

  const { income, homeType } = pick(['income', 'homeType'])(initialInput)

  const conformedValue = {
    ...initialDefaults,
    totalIncome: income.income,
    homeType,
  };

  const repaintMap = ({
    totalIncome,
    homeType,
    percentage,
  }) => {
    gj.setStyle(({ properties: { zillow: { timeline } } }) => {
      const regionColor = compose(
        defaultTo('white'),
        curry(categoryOfAffordability),
        curry(monthlyCost => calculateCostRatioPercentage(totalIncome, monthlyCost, percentage)),
        get(homeType),
        get('2019-12')
      )(timeline)

      return {
        fillColor: regionColor,
        fillOpacity: 0.4,
        color: 'none',
        opacity: 0.8,
        weight: 1,
      }
    })
  }

  const handleSubmit = (data) => {
    repaintMap(data)
    setHousingPercentage(pick(['totalIncome', 'percentage'])(data));
  };

  useEffect(() => {
    if (initialInput){
      repaintMap(conformedValue);
      setHousingPercentage(pick(['totalIncome', 'percentage'])(conformedValue));
    }
  }, [initialInput]);

  return (
    <Box pad='large' background='white'>
      <RentCalculatorForm
        handleSubmit={handleSubmit}
        initialValue={initialInput}
      />
    </Box>
  );
}

export default FilterController;
