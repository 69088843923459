import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import L from 'leaflet';
import { useLeaflet } from 'react-leaflet';

function usePlugToMap({
  component: Component,
  position,
  name,
  ...rest
}) {
  const leaflet = useLeaflet();
  const container = L.DomUtil.create('div', name);
  useEffect(() => {
    const filter = L.control({ position });
    filter.onAdd = () => {
      L.DomEvent.disableScrollPropagation(container);
      L.DomEvent.disableClickPropagation(container);
      return container;
    }
    filter.addTo(leaflet.map)
  }, [container, leaflet.map, position]);
  const element = document.getElementsByClassName(name)[0];
  if (element){
    return ReactDOM.createPortal((<Component { ...rest }/>), element);
  }
  return null;
};

export default usePlugToMap;
