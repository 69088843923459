import React, { useReducer } from 'react'

const initialState = {
  percentage: 0,
  totalIncome: 0,
};

const housingPercentageReducer = (state, action) => ({
  ...action.data,
});

export const HousingPercentageContext = React.createContext();

function HousingPercentageProvider({ children }) {
  const [housingPercentage, dispatch] = useReducer(housingPercentageReducer, initialState)
  const setHousingPercentage = (data) => dispatch({ data });
  return (
    <HousingPercentageContext.Provider value={{
      housingPercentage,
      setHousingPercentage,
    }}>
      {children}
    </HousingPercentageContext.Provider>
  )
}

export default HousingPercentageProvider;
