import { useEffect, useState, } from 'react';

function useHeight() {
  const [height, setHeight] = useState(window.innerHeight);
  const onResize = event => setHeight(event.target.innerHeight)
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  return height;
}

export default useHeight;
