import React, { useState } from 'react';
import {
  FormField,
  Select,
  Button,
  Box,
  Text,
  RangeInput,
  Layer,
  Heading,
} from 'grommet';

import {
  Form, Formik, Field, FieldArray,
} from 'formik';

import { Close as CloseIcon } from 'grommet-icons';

import reduce from 'lodash/fp/reduce';

import { default as HOUSING_TYPES } from '../../constants/housingTypes';

import IncomeCard from './IncomeCard';
import InsightCalculations from './InsightCalculations';
import AdditionalIncomesLayer from './AdditionalIncomesLayer';
import AutoSubmit from './AutoSubmit';

import { prettyNumber } from '../../utils/numbers';

function RentCalculatorForm ({
  handleSubmit,
  initialValue,
}) {
  const [display, setDisplay] = useState(false);

  const combineMultipleIncomes = reduce((total, { income }) => total + income, 0);

  const submitHandler = ({ incomes, homeType, percentage }) => {
    const totalIncome = combineMultipleIncomes(incomes);
    return handleSubmit({ totalIncome, homeType, percentage });
  };

  return (
    <Formik
      initialValues={{
        incomes: [initialValue.income],
        homeType: initialValue.homeType,
        percentage: 28,
      }}
      onSubmit={submitHandler}
    >
        {(props) => (
          <Form
            onSubmit={props.handleSubmit}
          >
            <Heading level={2} margin={{ bottom: 'small' }}>Housing Affordability</Heading>
            <FieldArray name='incomes'
              render={(helpers) => (
                <Box gap='small'>
                    {props.values.incomes.length > 1 ? props.values.incomes.map((income, idx) => (
                      <Box
                        key={idx}
                        border={true}
                        direction='row'
                        align='center'
                      >
                        <Box direction='row' justify='start' gap='xsmall' pad='xsmall' basis='full' align='center'>
                          <Text weight='bold'>
                            {income.occupation}
                          </Text>
                          |
                          <Text>
                            {prettyNumber(income.income)}
                          </Text>
                        </Box>
                        <Button
                          fill={false}
                          icon={<CloseIcon size='small' />}
                          onClick={() => helpers.remove(idx)}
                        />
                      </Box>
                    ))
                    : (
                      <IncomeCard idx={0} />
                    )}
                </Box>
              )}
            />
            <Box direction='row' justify='end'>
              <Button onClick={() => setDisplay(true)}>
                {props.values.incomes.length > 1 ? 'Edit Incomes' : 'Add Income'}
              </Button>
              {display && (
                <Layer
                  onEsc={() => setDisplay(false)}
                  onClickOutside={() => setDisplay(false)}
                  position='right'
                  full='vertical'
                >
                  <AdditionalIncomesLayer
                    onClose={() => setDisplay(false)}
                  />
                </Layer>
              )}
            </Box>
            {props.values.incomes.length > 1 && (
              <Text weight='bold'>
                Current Total: {prettyNumber(combineMultipleIncomes(props.values.incomes))}
              </Text>
            )}
            <Field name='homeType'>
              {({ field, form }) => (
                <FormField label='Housing Type'>
                  <Select
                    onChange={({ value }) => form.setFieldValue(field.name, value)}
                    options={HOUSING_TYPES}
                    value={field.value}
                  />
                </FormField>
              )}
            </Field>
            <Box gap='small' margin={{ vertical: 'medium' }}>
              <Text weight='bold'>Income Towards Housing</Text>
              <Box direction='row'>
                <Text weight='bold'>{props.values.percentage}%</Text>
                &nbsp;
                <Text>of total amount</Text>
              </Box>
              <Field name='percentage'>
                {({ field, form }) => (
                  <RangeInput
                    min={15}
                    max={40}
                    onChange={({ target: { value } }) => form.setFieldValue(field.name, value)}
                    value={field.value}
                  />
                )}
              </Field>
              <InsightCalculations
                salary={combineMultipleIncomes(props.values.incomes)}
                percent={props.values.percentage}
              />
            </Box>
            <Box direction='row' justify='end' gap='small'>
              <Button secondary label='Reset all' onClick={props.handleReset}/>
              <AutoSubmit />
            </Box>
          </Form>
        )}
    </Formik>
  );
}

export default RentCalculatorForm;

