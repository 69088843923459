import React from 'react';
import { Box, Text } from 'grommet';
import { formatAmount } from '../../utils/numbers';

const RegionTooltip = ({
  city,
  zipCode,
  housingStats,
}) => (
  <Box>
    <Text weight='bold'>{city}</Text>
    <Text>{zipCode}</Text>
    <Box
      margin={{ vertical: 'xsmall' }}
    >
      {
        housingStats && housingStats.map(({ type, value }) => (
          <Box direction='row' align='center'>
            <Text size='small'>{type}</Text>
            :&nbsp;
            <Text size='small'>{formatAmount(value)}</Text>
          </Box>
        ))
      }
    </Box>
  </Box>
);

export default RegionTooltip;
