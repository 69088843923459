export default {
  PERFECT: {
    color: 'blue',
    maxValue: 50,
    legendLabel: 'Less than 7% of income',
  },
  EASY: {
    color: 'cyan',
    maxValue: 80,
    legendLabel: 'Less than 17% of income',
  },
  MEDIUM: {
    color: 'green',
    maxValue: 100,
    legendLabel: 'Less than 27% of income',
  },
  HARD: {
    color: 'yellow',
    maxValue: 110,
    legendLabel: 'Less than 29% of income',
  },
  DIFFICULT: {
    color: 'orange',
    maxValue: 120,
    legendLabel: 'Less than 38% of income',
  },
  IMPOSSIBLE: {
    color: 'red',
    maxValue: 121,
    legendLabel: 'Over 39% of income',
  }
}
