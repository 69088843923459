import React from 'react';
import { Box, Heading, Text } from 'grommet';

import SplashForm from './form';

function Splash({ onInitialSubmit }) {
  return (
    <Box fill={true} align='center' justify='center'>
      <Box
        background='white'
        fill='horizontal'
        height='medium'
        direction='row'
        pad='large'
        justify='center'
        gap='medium'
      >
        <Box basis='1/3'>
          <Heading level={3}>Housing Affordability Map</Heading>
          <Text>Lorem Ipsum</Text>
        </Box>
        <Box>
          <SplashForm handleSubmit={onInitialSubmit} />
        </Box>
      </Box>
    </Box>
  );
}

export default Splash;
