export default [
  'Studio',
  '1Bedroom',
  '2Bedroom',
  '3Bedroom',
  '4Bedroom',
  '5Bedroom',
  '5BedroomOrMore',
  'AllHomes',
  'CondoCoop',
  'DuplexTriplex',
  'Mfr5Plus',
  'Sfr',
];


