import React from 'react';
import {
  Box,
  FormField,
  Select,
  TextInput,
  Button,
} from 'grommet';
import { Formik, Form, Field } from 'formik';

import { default as OCCUPATIONS } from '../../constants/occupations';
import { default as HOUSING_TYPES } from '../../constants/housingTypes';
import { prettyNumber, unprettyNumber } from '../../utils/numbers';

function SplashForm({ handleSubmit }) {
  return (
    <Formik
      initialValues={{
        income: {
          occupation: 'Other',
          income: 0,
        },
        homeType: 'Studio',
      }}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form
          onSubmit={props.handleSubmit}
        >
          <Box direction='row' gap='medium'>
            <Box>
              <Field name='income.occupation'>
                {({ field, form }) => (
                  <FormField label='Occupation'>
                    <Select
                      onChange={({ value }) => {
                        form.setFieldValue(`income.income`, value.salary)
                        form.setFieldValue(field.name, value.occupation)
                      }}
                      options={OCCUPATIONS}
                      labelKey='occupation'
                      value={field.value}
                    />
                  </FormField>
                )}
              </Field>
              <Field name={`income.income`}>
                {({ field, form }) => (
                  <FormField label='Income'>
                    <TextInput
                      value={prettyNumber(field.value)}
                      onChange={({ target: { value } }) => form.setFieldValue(field.name, unprettyNumber(value) || 0)}
                      placeholder='Enter yearly income'
                    />
                  </FormField>
                )}
              </Field>
            </Box>
            <Box>
              <Field name='homeType'>
                {({ field, form }) => (
                  <FormField label='Housing Type'>
                    <Select
                      onChange={({ value }) => form.setFieldValue(field.name, value)}
                      options={HOUSING_TYPES}
                      value={field.value}
                    />
                  </FormField>
                )}
              </Field>
            </Box>
          </Box>
          <Box direction='row' justify='end'>
            <Button type='submit' primary label='Check' />
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default SplashForm;
