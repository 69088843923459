import { deepMerge } from 'grommet/utils/object';
import baseTheme from '../../baseTheme';

export default deepMerge(baseTheme, {
  global: {
    font: {
      family: 'Roboto',
      size: '14px',
      height: '20px',
    },
    drop: {
      zIndex: 1000,
    }
  },
  layer: {
    zIndex: 1000,
  }
});
